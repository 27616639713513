export const FETCH_KUBUN_MASTER = 'FETCH_KUBUN_MASTER';
export const FETCH_ELECTRIC_MASTER = 'FETCH_ELECTRIC_MASTER';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';

export function fetchKubunMaster(kubun) {
    return {
        type: FETCH_KUBUN_MASTER,
        kubun: kubun
    }
}

export function fetchElectricMaster(electric) {
    return {
        type: FETCH_ELECTRIC_MASTER,
        electric: electric
    }
}
