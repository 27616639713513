import React, { Component } from "react";

class ScrollTopButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            intervalId: 0,
            isVisible: false,
        };
    }

    componentDidMount() {
        if (window.location.pathname === "/success" ||  window.location.pathname === "/finish") {
            this.setState({ isVisible: false });
        }
    }

    scrollStep() {
        const { intervalId } = this.state;
        if (window.pageYOffset === 0) {
            clearInterval(intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop() {
        let intervalId = setInterval(
            this.scrollStep.bind(this),
            this.props.delayInMs
        );
        this.setState({ intervalId: intervalId });
    }

    render() {
        const { history } = this.props;
        const { isVisible } = this.state;

        return (
            <React.Fragment>
                {isVisible && 
                    <button
                        title="トップに戻る"
                        className="scroll"
                        onClick={() => {
                            this.setState({ isVisible: false });
                            history.push('/entry')
                        }}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ScrollTopButton;
