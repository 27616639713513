import React from 'react';

// Customer
const Entry = React.lazy(() => import('./App/pages/customer/Entry'));
const CustomerInfo = React.lazy(() => import('./App/pages/customer/CustomerInfo'));
const PaymentOption = React.lazy(() => import('./App/pages/customer/PaymentOption'));
const Payment = React.lazy(() => import('./App/pages/customer/Payment'));
const RegisterConfirmation = React.lazy(() => import('./App/pages/customer/RegisterConfirmation'));
const Registered = React.lazy(() => import('./App/pages/customer/Registered'));
const DenkiCompany = React.lazy(() => import('./App/pages/customer/DenkiCompany'));
const InvoiceCheck = React.lazy(() => import('./App/pages/customer/InvoiceCheck'));
const DenkiSiteCheck = React.lazy(() => import('./App/pages/customer/DenkiSiteCheck'));
const AuContract = React.lazy(() => import('./App/pages/customer/AuContract'));
const Contract = React.lazy(() => import('./App/pages/customer/Contract'));
const ContractUpload = React.lazy(() => import('./App/pages/customer/ContractUpload'));
const ContractLater = React.lazy(() => import('./App/pages/customer/ContractLater'))
const Success = React.lazy(() => import('./App/pages/customer/Success'));
const Finish = React.lazy(() => import('./App/pages/customer/Finish'));

// Dairiten
const DairitenEntry = React.lazy(() => import('./App/pages/dairiten/Entry'));
const DairitenConfirmation = React.lazy(() => import('./App/pages/dairiten/Confirm'));
const DairitenSuccess = React.lazy(() => import('./App/pages/dairiten/Success'));

// LP
const LPCustomerInfo = React.lazy(() => import('./App/pages/lp/CustomerInfo'));
const LPPaymentOption = React.lazy(() => import('./App/pages/lp/PaymentOption'));
const LPPayment = React.lazy(() => import('./App/pages/lp/Payment'));
const LPConfirmation = React.lazy(() => import('./App/pages/lp/Confirm'));
const LPSuccess = React.lazy(() => import('./App/pages/lp/Success'));
const LPFinish = React.lazy(() => import('./App/pages/lp/Finish'));

// Home
const Campaign = React.lazy(() => import('./App/pages/home/Campaign'));
const Present = React.lazy(() => import('./App/pages/home/Present'));

// Other
const NotFound = React.lazy(() => import('./App/pages/NotFound'));

const route = [
    // Customer
    { path: '/', exact: true, name: '申込区分指定画面', component: Entry },
    { path: '/entry', exact: true, name: '申込区分指定画面', component: Entry },
    { path: '/customer_info_hikkoshi', exact: true, name: '基本情報入力画面', component: CustomerInfo },
    { path: '/customer_info_imaue', exact: true, name: '基本情報入力画面', component: CustomerInfo },
    { path: '/payment_option', exact: true, name: '支払い方法選択画面', component: PaymentOption },
    { path: '/payment', exact: true, name: '支払情報入力画面', component: Payment },
    { path: '/register_confirmation', exact: true, name: '電力情報確認画面', component: RegisterConfirmation },
    { path: '/registered', exact: true, name: '申込完了画面', component: Registered },
    { path: '/denki_company', exact: true, name: '電力会社情報入力画面', component: DenkiCompany },
    { path: '/invoice_check', exact: true, name: '検針表確認画面', component: InvoiceCheck },
    { path: '/denki_site_check', exact: true, name: 'マイページ確認画面', component: DenkiSiteCheck },
    { path: '/contract_au', exact: true, name: 'ａｕの電力情報入力画面', component: AuContract },
    { path: '/contract', exact: true, name: '電力情報入力画面', component: Contract },
    { path: '/contract_with_search', exact: true, name: '電力情報入力画面', component: Contract },
    { path: '/contract_upload', exact: true, name: '電力情報アップロード画面', component: ContractUpload },
    { path: '/contract_later', exact: true, name: '電力情報後で入力画面', component: ContractLater },
    { path: '/success_contract_later', exact: true, name: '完了画面（電力情報後で入力）', component: Success },
    { path: '/success', exact: true, name: '完了画面', component: Success },
    { path: '/finish', exact: true, name: '完了画面', component: Finish },

    // Dairiten
    { path: '/dairiten/:id', exact: true, name: '代理店向けの申込画面', component: DairitenEntry },
    { path: '/confirmation_dairiten/:id', exact: true, name: '代理店向けの情報確認画面', component: DairitenConfirmation },
    { path: '/success_dairiten/:id', exact: true, name: '代理店向けの登録完了画面', component: DairitenSuccess },

    // LP
    { path: '/lp_customer_info/:id', exact: true, name: 'ＬＰ専用の申込画面', component: LPCustomerInfo },
    { path: '/lp_payment_option', exact: true, name: 'ＬＰ専用の支払い方法選択画面', component: LPPaymentOption },
    { path: '/lp_payment', exact: true, name: 'ＬＰ専用の支払情報入力画面', component: LPPayment },
    { path: '/lp_confirmation', exact: true, name: 'ＬＰ専用の情報確認画面', component: LPConfirmation },
    { path: '/lp_success', exact: true, name: '完了画面', component: LPSuccess },
    { path: '/lp_finish', exact: true, name: '完了画面', component: LPFinish },

    // Home
    { path: '/present', exact: true, name: 'ステッカー申し込み', component: Present },
    { path: '/campaign', exact: true, name: 'キャンペーン', component: Campaign },

    // Other
    { path: '/*', exact: false, name: '見つからないページ', component: NotFound },
];

export default route;
