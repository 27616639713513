import React, { Component, Suspense } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import Aux from "../hoc/_Aux";
import routes from "../route";
import Loader from "./components/layout/Loader";
import ScrollToTop from "./components/layout/ScrollToTop";
import ScrollTopButton from "./components/ScrollTopButton";
import { fetchKubun, fetchElectric } from "../store/fetch";

// css
import "../../node_modules/font-awesome/scss/font-awesome.scss";

class App extends Component {
    componentDidMount = () => {
        const { 
            kubun, 
            fetchKubun, 
            electric, 
            fetchElectric 
        } = this.props;

        if (isEmpty(kubun)) {
            fetchKubun();
        }

        if (isEmpty(electric)) {
            fetchElectric();
        }
    };

    render() {
        const menu = routes.map((route, index) => {
            return route.component ? (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <route.component {...props} />}
                />
            ) : null;
        });

        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader />}>
                        <Switch>
                            {menu}
                        </Switch>
                    </Suspense>
                </ScrollToTop>
                <ScrollTopButton scrollStepInPx="50" delayInMs="16.66" history={this.props.history} />
            </Aux>
        );
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchKubun: fetchKubun,
            fetchElectric: fetchElectric,
        },
        dispatch
    );

export default withRouter(connect(null, mapDispatchToProps)(App));
