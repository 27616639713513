import axios from 'axios';

let SERVER_BASE_URL = process.env.REACT_APP_API_URL;

const initHttp = (optionalHeaders) => {
    let headers = {
        'Content-Type': 'application/json',
        ...optionalHeaders,
    };
    const authToken = localStorage.getItem('authToken');

    if (authToken) headers['Authorization'] = `Bearer ${authToken}`;

    let http = axios.create({ headers });
    http.interceptors.response.use(undefined, (error) => {
        return Promise.reject(error);
    });

    return http;
};

const postRequest = (path, data, resolve, reject, headers) => {
    const requestUrl = SERVER_BASE_URL + path;
    const http = initHttp(headers);
    http
        .post(requestUrl, data, headers)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        });
};

const getRequest = (path, data, resolve, reject, headers) => {
    const requestUrl = SERVER_BASE_URL + path;
    const http = initHttp(headers);
    http
        .get(requestUrl, data)
        .then((response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        });
};

const putRequest = (path, data, resolve, reject, headers) => {
    const requestUrl = SERVER_BASE_URL + path;
    const http = initHttp(headers);
    http
        .put(requestUrl, data)
        .then((response) => {
            resolve(response.data.response);
        })
        .catch((error) => {
            reject(error);
        });
};

const patchRequest = (path, data, resolve, reject, headers) => {
    const requestUrl = SERVER_BASE_URL + path;
    const http = initHttp(headers);
    http
        .patch(requestUrl, data)
        .then((response) => {
            resolve(response.data.response);
        })
        .catch((error) => {
            reject(error);
        });
};

const deleteRequest = (path, data, resolve, reject, headers) => {
    const requestUrl = SERVER_BASE_URL + path;
    const http = initHttp(headers);
    http
        .delete(requestUrl, data)
        .then((response) => {
            resolve(response.data.response);
        })
        .catch((error) => {
            reject(error);
        });
};

const api = {
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
}

export default api;
