import * as actionTypes from "./actions";

const initialState = {
    kubun: {},
    electric: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_KUBUN_MASTER:
            return {
                ...state,
                kubun: action.kubun,
            };
        case actionTypes.FETCH_ELECTRIC_MASTER:
            return {
                ...state,
                electric: action.electric,
            };
        case actionTypes.SET_PAGE_TITLE:
            return {
                ...state,
                routeProps: action.routeProps,
            };
        default:
            return state;
    }
};

export default reducer;
