import { fetchElectricMaster, fetchKubunMaster } from './actions';
import apiCaller from '../utils/api';

export function fetchKubun() {
    return dispatch => apiCaller.getRequest('kubunMaster/list', {},
        (response) => {
            dispatch(fetchKubunMaster(response.data.results.data));
        },
        (error) => {
            dispatch(fetchKubunMaster({}));
            return {};
        }
    );
};

export function fetchElectric() {
    return dispatch => apiCaller.getRequest('electricMaster/list', {},
        (response) => {
            dispatch(fetchElectricMaster(response.data.results.data));
        },
        (error) => {
            dispatch(fetchElectricMaster({}));
            return {};
        }
    );
};
